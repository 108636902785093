import { Component, Input } from '@angular/core';
import { EventTypeIcons } from '@index/enums';

@Component({
  selector: 'app-sport-icon',
  templateUrl: './sport-icon.component.html',
  styleUrls: ['./sport-icon.component.scss'],
})
export class SportIconComponent {
  @Input()
  sport: string | undefined = '';

  /** Get Angular Material Icon for {@link GAME_TYPES} */
  get gameTypeIcon() {
    return EventTypeIcons[this.sport || ''] || 'sports';
  }
}
