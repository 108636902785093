import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SportIconComponent } from '@shared/components/sport-icon/sport-icon.component';

const ANGULAR_MATERIAL_MODULES = [
  MatIconModule,
];

const CORE_MODULES = [CommonModule];

const COMPONENTS = [SportIconComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...CORE_MODULES, ...ANGULAR_MATERIAL_MODULES],
  exports: [...COMPONENTS],
})
export class SportIconModule { }
